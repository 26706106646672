import React from "react";
import { Pie } from "@ant-design/plots";

function Chart({ data, xField, yField }) {
  const config = {
    data,
    angleField: yField,
    colorField: xField,
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  return data?.length ? <Pie {...config} /> : null;
}

export default Chart;
