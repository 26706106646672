import React, { useState } from "react";
import { useLocalStorage } from "react-use";
import { useHistory } from "react-router-dom";
import {
  chakra,
  Box,
  Flex,
  Divider,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Text,
  Tbody,
  useColorModeValue,
} from "@chakra-ui/react";
import Search from "@/Generics/Search/Search";

export default function Following() {
  const history = useHistory();
  const [wallet, setWallet] = useState("");
  const [watchedWallets, setWatchedWallets] = useLocalStorage(
    "watchedWallets",
    ""
  );

  const tableTheme = useColorModeValue("alphabitLight", "alphabitDark");
  const themeBackground = useColorModeValue("gray.100", "gray.900");

  const handleInput = ({ target: { value } }) => {
    setWallet(value);
  };

  const onSubmit = () => {
    setWallet("");

    if (!watchedWallets) {
      return setWatchedWallets([wallet]);
    }

    setWatchedWallets([...watchedWallets, wallet]);
  };

  return (
    <Box>
      <Box bg={themeBackground} px={{ base: 4, lg: 8 }} paddingTop="0">
        <Box>
          <chakra.h2
            fontSize={{ base: "3xl", sm: "4xl" }}
            fontWeight="extrabold"
            letterSpacing="tight"
            lineHeight="shorter"
          >
            <chakra.span
              display="block"
              color={useColorModeValue("black", "gray.100")}
            >
              Follow Wallets Today
            </chakra.span>
          </chakra.h2>
          <Text color="gray.400" marginTop="3">
            Here you can find the wallets of whales with any coin or token that
            you want to track. Just search the wallet address to the and you
            will see all information about it.
          </Text>
        </Box>
        <Flex pt="25">
          <Search
            onChange={handleInput}
            value={wallet}
            placeholder="Add a Wallet Address"
            style={{ marginRight: "10px" }}
          />
          <Button variant="alphabitPrimary" onClick={onSubmit}>
            Submit
          </Button>
        </Flex>
      </Box>
      <Divider pt="5" />
      <Box maxW="7xl" mx="auto">
        {!!watchedWallets.length && (
          <Box>
            <Table variant={tableTheme}>
              <Thead>
                <Tr>
                  <Th>Wallet</Th>
                </Tr>
              </Thead>
              <Tbody>
                {watchedWallets.map((wallet) => {
                  return (
                    <Tr>
                      <Td
                        onClick={() => history.push(`/dashboard/${wallet}`)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {wallet}
                      </Td>
                      <Td textAlign="end">
                        <Button
                          onClick={() => {
                            setWatchedWallets(
                              watchedWallets.filter(
                                (savedWallet) => savedWallet !== wallet
                              )
                            );
                          }}
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        )}
      </Box>
    </Box>
  );
}
