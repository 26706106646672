import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./Landing/Landing";
import Leaderboard from "./Leaderboard/Leaderboard";
import Dashboard from "@/Wallet/Dashboard/Dashboard";
import Sidebar from "@/Sidebar/Sidebar";
import Transactions from "@/Transactions/Transactions";
import MyWallet from "@/MyWallet/MyWallet";
import Parachains from "@/Parachains/Parachains";
import ParachainTable from "@/Parachains/ParachainTable";
import Following from "@/Following/Following";
import Borrowers from "@/Borrowers/Borrowers";
import Stakers from "@/Stakers/Stakers";
import Whales from "@/Whales/Whales";

import "./App.css";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Sidebar>
          <Route exact path="/borrowers">
            <Borrowers />
          </Route>
          <Route exact path="/stakers">
            <Stakers />
          </Route>
          <Route exact path="/whales">
            <Whales />
          </Route>
          <Route exact path="/leaderboard">
            <Leaderboard />
          </Route>
          <Route exact path="/dashboard/:address">
            <Dashboard />
          </Route>
          <Route exact path="/transactions">
            <Transactions />
          </Route>
          <Route exact path="/wallet">
            <MyWallet />
          </Route>
          <Route exact path="/parachains">
            <Parachains />
          </Route>
          <Route exact path="/parachains/:selectedChain/:paraId">
            <ParachainTable />
          </Route>
          <Route exact path="/following">
            <Following />
          </Route>
        </Sidebar>
      </Switch>
    </Router>
  );
}

export default Routes;
