import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import chaindata from "@talismn/chaindata-js";
import { useGetTokenDetails } from "@/Leaderboard/useTopKaruraWallets";

const POLKADOT_CROWDLOANS_ENDPOINT =
  "https://api.subquery.network/sq/interlay/polkadot-crowdloan-indexer";

const KUSAMA_CROWDLOANS_ENDPOINT =
  "https://api.subquery.network/sq/interlay/kusama-crowdloan-indexer";

const COMMON_CROWDPOINT_GQL_FRAGMENT = `
query {
  crowdloans(orderBy: RAISED_DESC) {
    nodes {
      parachain {
        paraId
      }
      id
      isFinished
      raised
      contributions(orderBy: AMOUNT_DESC, first: 10) {
        nodes {
          id
          account
          amount
        }
      }
    }
  }
}
`;

const PARACHAIN_BY_ID_GQL_FRAGMENT = (paraId) => {
  return `query {
      parachains(filter: { paraId: { equalTo: ${paraId} }}) {
       nodes {
        id
        contributions(orderBy: AMOUNT_DESC, first: 1000) {
          nodes {
            id
            amount
            account
            blockNum
            createdAt
          }
        }
      }
      }
    }
    `;
};

export const useTopParachainWallets = (selectedChain) => {
  useGetTokenDetails();

  return useQuery(["topPolkadotParachainWallets", selectedChain], async () => {
    const chainEndpoint =
      selectedChain === "polkadot"
        ? POLKADOT_CROWDLOANS_ENDPOINT
        : KUSAMA_CROWDLOANS_ENDPOINT;
    try {
      const { crowdloans } = await request(
        chainEndpoint,
        gql`
          ${COMMON_CROWDPOINT_GQL_FRAGMENT}
        `
      );
      return crowdloans;
    } catch (error) {
      console.error(error);
    }
  });
};

export const useParachainById = (paraId, selectedChain) => {
  return useQuery(
    ["parachainContributionById", paraId, selectedChain],
    async () => {
      const chainEndpoint =
        selectedChain === "polkadot"
          ? POLKADOT_CROWDLOANS_ENDPOINT
          : KUSAMA_CROWDLOANS_ENDPOINT;
      try {
        const { parachains } = await request(
          chainEndpoint,
          gql`
            ${PARACHAIN_BY_ID_GQL_FRAGMENT(paraId)}
          `
        );
        return parachains.nodes[0];
      } catch (error) {
        console.error(error);
      }
    }
  );
};

export const useGetChainData = () => {
  return useQuery("chainData", async () => {
    const chains = await chaindata.chains();
    return chains;
  });
};
