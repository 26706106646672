import axios from "axios";
import { useQuery } from "react-query";
import { POLKADATA_EXTERNAL_API } from "@/utils";

export const useWhaleActivity = (days) => {
  return useQuery(
    ["whaleActivity", days],
    async () => {
      try {
        const { data } = await axios.get(
          `${POLKADATA_EXTERNAL_API}/accounts/whales/?days=${days}`,
          {
            headers: {
              API_KEY: process.env.REACT_APP_API_KEY,
            },
          }
        );
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    {
      initialData: [],
    }
  );
};
