import MoonbeamImage from "@/assets/moonbeam.webp";
import AcalaImage from "@/assets/acala.webp";
import AltairImage from "@/assets/altair.webp";
import AstarImage from "@/assets/astar.webp";
import MoonriverImage from "@/assets/moonriver.webp";
import CalamariImage from "@/assets/calamari.png";

export const KARURA_SUBSCAN_ENDPOINT = "https://karura.api.subscan.io";
export const KARURA_SUBQUERY_ENDPOINT =
  "https://api.subquery.network/sq/AcalaNetwork/karura";

export const POLKADATA_EXTERNAL_API =
  "https://backend-k3w3s26pfq-uc.a.run.app/api/v1";

export const FETCHER_API = "https://app-k3w3s26pfq-uc.a.run.app/api/v1";
export const COINGECKO_API = "https://api.coingecko.com/api/v3";

export const formatNumberToCurrency = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

const TOKEN_IMG_URL =
  "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/";

export const tokenToImage = (token) => {
  console.log(token);
  switch (token) {
    case "KSM":
      return `${TOKEN_IMG_URL}//kusama/info/logo.png`;
    case "MOVR":
      return MoonriverImage;
    case "GLMR":
      return MoonbeamImage;
    case "AIR":
      return AltairImage;
    case "ACA":
      return AcalaImage;
    case "KMA":
      return CalamariImage;
    case "ASTR":
      return AstarImage;
    case "LKSM":
      return `https://apps.karura.network/static/media/LKSM.72609f84.svg`;
    case "KAR":
      return "https://resources.acala.network/tokens/KAR.png";
    case "KUSD":
      return "https://apps.karura.network/static/media/KUSD.04eb6cee.svg";
    case "USD":
      return `${TOKEN_IMG_URL}/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png`;
    case "BNC":
      return "https://apps.karura.network/static/media/BNC.ec084af9.svg";
    case "DOT":
      return `${TOKEN_IMG_URL}/polkadot/info/logo.png`;
    default:
      return "";
  }
};

export const MAIN_CHAIN_MAP = {
  POLKADOT: "polkadot",
  KUSAMA: "kusama",
};

export const TOKEN_MAP = {
  USD: "USD",
};

export const convertParaIdToChain = ({ paraId, chainData, selectedChain }) => {
  if (selectedChain === MAIN_CHAIN_MAP.POLKADOT) {
    return chainData[`0-${paraId}`];
  }
  return chainData[`2-${paraId}`];
};
