import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { POLKADATA_EXTERNAL_API } from "@/utils";

export const useTransfers = (walletAddress) => {
  const queryClient = useQueryClient();
  const tokens = queryClient.getQueryData("tokenDetails");

  return useQuery(
    ["userActivity", walletAddress],
    async () => {
      try {
        const { data } = await axios.get(
          `${POLKADATA_EXTERNAL_API}/accounts/transfers/${walletAddress}`,
          {
            headers: {
              API_KEY: process.env.REACT_APP_API_KEY,
            },
          }
        );

        return data.map((transfers) => {
          return {
            ...transfers,
            volume: transfers.volume * tokens?.karura?.usd,
            volume_receive: transfers.volume_receive * tokens?.karura?.usd,
            volume_send: transfers.volume_send * tokens?.karura?.usd,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    {
      enabled: !!tokens,
    }
  );
};
