import React from "react";
import { Flex } from "@chakra-ui/react";
import Portfolio from "./Portfolio";
import StakedTokens from "./StakedCoins";
import LiquidityPools from "./LiquidityPools";

function Holdings({
  accountDetails,
  stakedTokens,
  liquidityPools,
  tokens,
  walletAddress,
  isFetching,
}) {
  return (
    <div>
      <Portfolio
        walletAddress={walletAddress}
        accountDetails={accountDetails}
        tokens={tokens}
        isFetching={isFetching}
      />
      <Flex>
        <StakedTokens stakedTokens={stakedTokens} />
        <LiquidityPools liquidityPools={liquidityPools} />
      </Flex>
    </div>
  );
}

export default Holdings;
