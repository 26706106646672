import axios from "axios";
import { useQuery } from "react-query";
import { POLKADATA_EXTERNAL_API } from "@/utils";

const TOP_BORROWER_ENDPOINTS = ["collateral", "debt", "ratio"];

export const useTopBorrowersCollateral = () => {
  return useQuery("topBorrowersCollateral", async () => {
    try {
      return await Promise.all(
        TOP_BORROWER_ENDPOINTS.map(async (endpoint) => {
          const { data } = await axios.get(
            `${POLKADATA_EXTERNAL_API}/accounts/top_borrowers/${endpoint}/`,
            {
              headers: {
                API_KEY: process.env.REACT_APP_API_KEY,
              },
            }
          );

          return { endpoint, data };
        })
      );
    } catch (error) {
      console.error(error);
    }
  });
};
