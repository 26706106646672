import React from "react";
import {
  chakra,
  Container,
  Flex,
  Table,
  Image,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatNumberToCurrency, tokenToImage } from "@/utils";

function StakedCoins({ stakedTokens }) {
  return (
    <Container
      maxHeight="100%"
      w="100%"
      borderRadius="16"
      padding="6"
      marginLeft="6"
      marginTop="6"
      variant="wallet"
    >
      <chakra.h4
        fontSize={{ base: "3xl", sm: "4xl", lg: "xl" }}
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight="shorter"
        color={useColorModeValue("gray.400", "gray.100")}
        mb="3"
      >
        Staked
      </chakra.h4>
      {stakedTokens?.length ? (
        <Table>
          <Thead>
            <Tr>
              <Th>Token</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {stakedTokens.map(({ tokenInformation, value, usd }) => {
              return (
                <Tr>
                  <Td>
                    <Flex alignItems={"center"}>
                      <Image
                        src={tokenToImage(tokenInformation.tokenDisplay)}
                        borderRadius={"50%"}
                        boxSize="30px"
                        marginRight="3"
                      />
                      <p>{tokenInformation.tokenDisplay}</p>
                    </Flex>
                  </Td>
                  <Td>
                    {value}
                    <br />
                    {formatNumberToCurrency(usd)}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <p>No staked tokens</p>
      )}
    </Container>
  );
}

export default StakedCoins;
