import React from "react";
import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
import Feature from "./Feature";
import DashboardImage from "@/assets/Group.png";
import KaruraText from "@/assets/karuraText.svg";
import AcalaText from "@/assets/acalaText.svg";

function Landing() {
  return (
    <div className="App">
      <header className="App-header">
        <Flex direction={{ lg: "row", md: "column", base: "column" }}>
          <Text
            fontSize={{ lg: "6xl", md: "6xl", base: "5xl" }}
            color="whiteAlpha"
            fontWeight="extrabold"
            marginRight="5"
          >
            Better data.
          </Text>{" "}
          <Text
            fontSize={{ lg: "6xl", md: "6xl", base: "5xl" }}
            bgGradient="linear(to-l, #2599FA, #39EFE3)"
            bgClip="text"
            fontWeight="extrabold"
          >
            Better returns.
          </Text>
        </Flex>
        <Box maxW={{ sm: "50%", md: "50%", lg: "50%" }} p={{ base: "1em" }}>
          <Text
            fontSize="lg"
            color="whiteAlpha.600"
            align={{ base: "left", lg: "center" }}
          >
            Track and monitor the top investors in Polkadot to discover new
            opportunities and invest with confidence.
          </Text>
        </Box>
        <Flex
          direction={{ base: "column", lg: "row", sm: "row" }}
          justifyContent={{ lg: "center" }}
          width={{ base: "75%", sm: "fit-content", md: "fit-content" }}
        >
          <Box pr="3">
            <a
              href="https://acala.alphabit.ai"
              target="_blank"
              rel="noopener"
              aria-label="Acala"
            >
              <Button
                marginTop="5"
                size="lg"
                color="whiteAlpha"
                width={{ base: "100%" }}
              >
                <Image src={AcalaText} alt="logo" width={100} height={100} />
              </Button>
            </a>
          </Box>
          <a
            href="https://karura.alphabit.ai"
            target="_blank"
            rel="noopener"
            aria-label="Karura"
          >
            <Box>
              <Button
                marginTop="5"
                size="lg"
                color="whiteAlpha"
                width={{ base: "100%" }}
                pb={"1em"}
              >
                <Image src={KaruraText} alt="logo" width={100} height={100} />
              </Button>
            </Box>
          </a>
        </Flex>
        <Button
          colorScheme="blue"
          bgColor={"blue.600"}
          marginTop="5"
          size="lg"
          color="whiteAlpha"
          leftIcon={<FaDiscord />}
          onClick={() => window.open("https://discord.gg/wSv2vH9QBf", "_blank")}
        >
          Join our Discord
        </Button>
      </header>
      <Box
        w={{ base: "full", md: 10 / 12 }}
        mx="auto"
        mt={-100}
        textAlign="center"
      >
        <Image
          w="full"
          rounded="lg"
          src={DashboardImage}
          alt="Hellonext feedback boards software screenshot"
        />
      </Box>
      <Feature />
    </div>
  );
}

export default Landing;
