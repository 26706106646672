import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Tabs,
  TabList,
  Text,
  Flex,
  TabPanel,
  Divider,
  TabPanels,
  Tab,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatNumberToCurrency } from "@/utils";
import { useTopBorrowersCollateral } from "./useTopBorrowers";

function Borrowers() {
  const history = useHistory();
  const [selectedBorrower, setSelectedBorrower] = useState(0);
  const { data, isFetching } = useTopBorrowersCollateral();

  const BORROWER_KEYS = ["debt", "collateral", "ratio"];

  const selectedData = !isFetching
    ? data?.find((key) => {
        return key.endpoint === BORROWER_KEYS[selectedBorrower];
      })
    : [];

  const determineKey = (account, endpoint) => {
    switch (endpoint) {
      case "collateral":
        return account.total_collateral_usd;
      case "debt":
        return account.total_debt_usd;
      case "ratio":
        return account.total_ratio;
      default:
        return "";
    }
  };

  const onTabChange = (event) => {
    setSelectedBorrower(event);
  };

  const tableTheme = useColorModeValue("alphabitLight", "alphabitDark");

  const renderTable = (selectedData) => {
    return (
      <Table variant={tableTheme}>
        <Thead>
          <Tr>
            <Th>Place</Th>
            <Th>Trader</Th>
            <Th>Wallet</Th>
            <Th isNumeric>Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isFetching &&
            selectedData?.data.map((account, idx) => {
              return (
                <Tr style={{ cursor: "pointer" }} key={idx}>
                  <Td>{idx + 1}</Td>
                  <Td>
                    <Avatar
                      size={"sm"}
                      src={`https://avatars.dicebear.com/api/bottts/${account.address}.svg`}
                    />
                  </Td>
                  <Td
                    onClick={() =>
                      history.push(`/dashboard/${account.address}`)
                    }
                  >
                    {account.address}
                  </Td>
                  <Td isNumeric>
                    {formatNumberToCurrency(
                      determineKey(account, selectedData.endpoint)
                    )}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    );
  };

  return (
    <Box as="main">
      <Tabs variant="alphabit" onChange={onTabChange}>
        <TabList>
          <Flex>
            <Text
              color="gray.400"
              textTransform="uppercase"
              fontSize="12px"
              alignSelf="center"
              pl="9"
              pr="7"
            >
              Select Option:
            </Text>
            <Tab>Debt</Tab>
            <Tab>Collateral</Tab>
            <Tab>Ratio</Tab>
          </Flex>
        </TabList>
        <Divider pt="5" />
        <TabPanels>
          {BORROWER_KEYS.map((val) => (
            <TabPanel>{renderTable(selectedData)}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Borrowers;
