import React from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatNumberToCurrency } from "@/utils";
import { useTopStakers } from "./useTopStakers";

function Stakers() {
  const history = useHistory();
  const { data, isFetching } = useTopStakers();
  const tableTheme = useColorModeValue("alphabitLight", "alphabitDark");

  return (
    <Box as="main">
      <Table variant={tableTheme}>
        <Thead>
          <Tr>
            <Th>Place</Th>
            <Th>Trader</Th>
            <Th>Wallet</Th>
            <Th isNumeric>Total Staked</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isFetching &&
            data?.map((account, idx) => {
              return (
                <Tr style={{ cursor: "pointer" }} key={idx}>
                  <Td>{idx + 1}</Td>
                  <Td>
                    <Avatar
                      size={"sm"}
                      src={`https://avatars.dicebear.com/api/bottts/${account.address}.svg`}
                    />
                  </Td>
                  <Td
                    onClick={() =>
                      history.push(`/dashboard/${account.address}`)
                    }
                  >
                    {account.address}
                  </Td>
                  <Td isNumeric>
                    {formatNumberToCurrency(
                      account.amount_stake_ksm + account.amount_stake_usd
                    )}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Stakers;
