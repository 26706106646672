import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  TabList,
  Text,
  Flex,
  Tab,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableCaption,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { useTopParachainWallets, useGetChainData } from "./useParachains";
import { convertParaIdToChain } from "@/utils";

const SELECTED_CHAINS = ["polkadot", "kusama"];

function Parachains() {
  const history = useHistory();
  const [selectedChain, setSelectedChain] = useState("polkadot");
  const { data, isFetching } = useTopParachainWallets(selectedChain);
  const { data: chainData } = useGetChainData();

  const onTabChange = (event) => {
    setSelectedChain(SELECTED_CHAINS[event]);
  };

  return (
    <Box as="main" minH="100">
      <Tabs variant="alphabit" onChange={onTabChange}>
        <TabList>
          <Flex>
            <Text
              color="gray.400"
              textTransform="uppercase"
              fontSize="12px"
              alignSelf="center"
              pl="6"
              pr="7"
            >
              Select Chain:
            </Text>
            <Tab>Polkadot</Tab>
            <Tab>Kusama</Tab>
          </Flex>
        </TabList>
      </Tabs>
      {isFetching ? (
        <Flex justifyContent="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      ) : (
        <Table variant="simple">
          <TableCaption>Top Parachain Contributions</TableCaption>
          <Thead>
            <Tr>
              <Th>Parachain</Th>
              <Th isNumeric>
                Total Raised ({selectedChain === "polkadot" ? "DOT" : "KSM"})
              </Th>
              {/* <Th>KSM Contributed</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data?.nodes?.map((crowdloan, idx) => {
                return (
                  <Tr
                    style={{ cursor: "pointer" }}
                    key={idx}
                    onClick={() =>
                      history.push(
                        `/parachains/${selectedChain}/${crowdloan.parachain.paraId}`
                      )
                    }
                  >
                    <Td>
                      {convertParaIdToChain({
                        paraId: crowdloan.parachain.paraId,
                        chainData,
                        selectedChain,
                      })}
                    </Td>
                    <Td isNumeric>
                      {new Intl.NumberFormat().format(
                        crowdloan.raised * Math.pow(10, -13)
                      )}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

export default Parachains;
