import React from "react";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useParams, useHistory } from "react-router-dom";
import { Table, Thead, Tr, Th, Tbody, Td, Box } from "@chakra-ui/react";
import { useParachainById } from "./useParachains";

dayjs.extend(LocalizedFormat);

function ParachainTable() {
  const history = useHistory();
  const { paraId, selectedChain } = useParams();

  const { data, isFetching } = useParachainById(paraId, selectedChain);

  return (
    <Box as="main">
      <Table variant="alphabit">
        <Thead>
          <Tr>
            <Th>Address</Th>
            <Th>Contributed</Th>
            <Th>Block Number</Th>
            <Th>Contribution Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isFetching &&
            data?.contributions?.nodes?.map(
              ({ account, amount, id, blockNum, createdAt }, idx) => {
                return (
                  <Tr key={idx}>
                    <Td
                      onClick={() => history.push(`/dashboard/${account}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {account}
                    </Td>
                    <Td isNumeric>
                      {new Intl.NumberFormat().format(
                        amount * Math.pow(10, -13)
                      )}
                    </Td>
                    <Td isNumeric>{blockNum}</Td>
                    <Td isNumeric>{dayjs(createdAt).format("LLL")} </Td>
                  </Tr>
                );
              }
            )}
        </Tbody>
      </Table>
    </Box>
  );
}

export default ParachainTable;
