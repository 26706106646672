import React from "react";
import { chakra, Container } from "@chakra-ui/react";
import PieChart from "@/PieChart/PieChart";

function CollateralRatio({ collateral }) {
  return (
    <Container borderRadius="16" padding="6" margin="6" variant="wallet">
      <chakra.h2
        fontSize={{ base: "3xl", sm: "4xl", lg: "xl" }}
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight="shorter"
        color={"gray.100"}
      >
        Collateral Ratio
      </chakra.h2>
      <PieChart data={collateral} />
    </Container>
  );
}

export default CollateralRatio;
