import React from "react";
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function Features() {
  const Feature = (props) => {
    return (
      <Flex>
        <Flex shrink={0}>
          <Flex
            alignItems="center"
            justifyContent="center"
            h={12}
            w={12}
            rounded="md"
            color="white"
          >
            <Icon
              boxSize={6}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              {props.icon}
            </Icon>
          </Flex>
        </Flex>
        <Box ml={4}>
          <chakra.dt
            fontSize="lg"
            fontWeight="medium"
            lineHeight="6"
            color={useColorModeValue("gray.900")}
          >
            {props.title}
          </chakra.dt>
          <chakra.dd mt={2} color={useColorModeValue("gray.500", "gray.400")}>
            {props.children}
          </chakra.dd>
        </Box>
      </Flex>
    );
  };
  return (
    <Flex
      p={20}
      w="auto"
      justifyContent="center"
      alignItems="center"
      flexDirection={{ base: "column" }}
    >
      <Box py={12} rounded="xl">
        <Box maxW="7xl" mx="auto" px={{ base: 4, lg: 8 }}>
          <Box textAlign={{ lg: "center" }}>
            <chakra.h2
              color={useColorModeValue("brand.600")}
              fontWeight="semibold"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Features
            </chakra.h2>
            <chakra.h3
              display="inline-flex"
              flexDirection={{ base: "column", lg: "row", md: "row" }}
              mt={2}
              fontSize={{ base: "3xl", sm: "4xl" }}
              lineHeight="8"
              fontWeight="extrabold"
              letterSpacing="tight"
              color={useColorModeValue("gray.900")}
            >
              A better way to discover&nbsp;
              <Text
                fontSize="4xl"
                bgGradient="linear(to-l, #2599FA, #39EFE3)"
                bgClip="text"
                fontWeight="extrabold"
              >
                blockchain&nbsp;
              </Text>{" "}
              trends
            </chakra.h3>
            <chakra.p
              mt={4}
              maxW="2xl"
              fontSize="xl"
              mx={{ lg: "auto" }}
              color={useColorModeValue("gray.500", "gray.400")}
            >
              Through a variety of on-chain analysis tools, we are able to bring
              clarity to activity happening in the Polkadot ecosystem.
            </chakra.p>
          </Box>

          <Box mt={10}>
            <Stack
              spacing={{ base: 10, md: 0 }}
              display={{ md: "grid" }}
              gridTemplateColumns={{ md: "repeat(2,1fr)" }}
              gridColumnGap={{ md: 8 }}
              gridRowGap={{ md: 10 }}
            >
              <Feature
                title="Discover New Opportunities"
                icon={
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  />
                }
              >
                Find new tokens or projects, see where money is moving, and
                track transactions down to the most granular level. Using
                Polkadata's on-chain metrics ensures you have an up-to-date
                visual for the blockchain transactions and trends that drive
                cryptocurrency markets.
              </Feature>

              <Feature
                title="Make Informed, Data-Driven Decisions"
                icon={
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                  />
                }
              >
                Maximizing returns in crypto requires an insightful, data-driven
                investment strategy. Polkadata provides you with an easy to
                understand description of the activity and portfolio of the top
                investors in crypto so that you can invest with the best.
              </Feature>

              <Feature
                title="Monitor All Your Assets in One Place "
                icon={
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                }
              >
                With great visibility comes great opportunity. Using Polkadata
                you can track your token, liquidity pool, and borrowing/lending
                performance across blockchains.
              </Feature>

              <Feature
                title="Perform Due Diligence"
                icon={
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                }
              >
                Gain insight and validate your hypothesis before you invest in a
                new crypto project or token. Compare your portfolio and study
                the real-time activity of the largest crypto investors to better
                identify what to buy and sell.
              </Feature>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}
