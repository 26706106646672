import axios from "axios";
import { useQuery } from "react-query";
import { POLKADATA_EXTERNAL_API } from "@/utils";

export const useUserActivity = (walletAddress, metric) => {
  return useQuery(["userActivity", walletAddress, metric], async () => {
    try {
      const { data } = await axios.get(
        `${POLKADATA_EXTERNAL_API}/accounts/activity/${walletAddress}?kind=${metric}`,
        {
          headers: {
            API_KEY: process.env.REACT_APP_API_KEY,
          },
        }
      );
      return data.extrinsics;
    } catch (error) {
      console.error(error);
    }
  });
};
