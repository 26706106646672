import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { POLKADATA_EXTERNAL_API, COINGECKO_API } from "@/utils";

export const useGetTokenDetails = () => {
  const queryClient = useQueryClient();

  return useQuery("getTokenDetails", async () => {
    try {
      const { data } = await axios.get(
        `${COINGECKO_API}/simple/price?ids=karura,kusama,polkadot,moonriver,calamari-network,altair,acala,moonbeam,astar&vs_currencies=usd`
      );
      queryClient.setQueryData("tokenDetails", data);
      return data;
    } catch (error) {
      console.error(error);
    }
  });
};

export const useTopKaruraWallets = () => {
  useGetTokenDetails();

  return useQuery("topKaruraWallets", async () => {
    const { data } = await axios.get(
      `${POLKADATA_EXTERNAL_API}/accounts/leaderboard/`,
      {
        headers: {
          API_KEY: process.env.REACT_APP_API_KEY,
        },
      }
    );

    return data;
  });
};
