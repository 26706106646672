import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { NavLink as ReactRouterLink } from "react-router-dom";
import DarkModeAlphabitLogo from "@/assets/logo.svg";
import LightModeAlphabitLogo from "@/assets/GrayAlphabit.svg";
import {
  Collapse,
  Link,
  Image,
  Avatar,
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  useColorModeValue,
  useColorMode,
  Switch,
  useDisclosure,
  LightMode,
} from "@chakra-ui/react";
import { FaBell } from "react-icons/fa";
import {
  FiMenu,
  FiHome,
  FiUmbrella,
  FiActivity,
  FiAward,
  FiInstagram,
  FiTwitter,
} from "react-icons/fi";
import { FaWallet } from "react-icons/fa";
import Search from "@/Generics/Search/Search";
import styles from "./Sidebar.module.css";

export default function Sidebar({ children }) {
  const sidebar = useDisclosure();
  const integrations = useDisclosure();
  const leaderboard = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  const navIconColor = useColorModeValue("gray.900", "gray.50");
  const socialColor = useColorModeValue("#f5f5f5", "gray.900");

  const history = useHistory();

  const [searchState, setSearchState] = useState("");

  const onSearch = ({ target: { value } }) => {
    setSearchState(value);
  };

  const onKeyPress = (event) => {
    if (event.code === "Enter") {
      history.push(`/dashboard/${searchState}`);
    }
  };

  const isFollowerOrWalletPage =
    history.location.pathname === "/following" ||
    history.location.pathname === "/wallet";

  const NavItem = (props) => {
    const { icon, children, to, ...rest } = props;
    return (
      <Flex>
        <Link
          as={to && ReactRouterLink}
          to={to && to}
          align="center"
          px="4"
          pl="6"
          py="3"
          w="100%"
          textAlign="left"
          cursor="pointer"
          _hover={{
            bg: useColorModeValue("gray.100", "gray.900"),
            color: useColorModeValue("gray.900", "white"),
          }}
          _focus={{
            boxShadow: "none",
          }}
          activeStyle={{
            color: "#10E3A4",
          }}
          activeClassName={styles.selected}
          role="group"
          fontWeight="semibold"
          transition=".15s ease"
          {...rest}
        >
          {icon && (
            <Icon
              mr="2"
              boxSize="4"
              _groupHover={{
                color: navIconColor,
              }}
              as={icon}
            />
          )}
          {children}
        </Link>
      </Flex>
    );
  };

  const SidebarContent = (props) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg={useColorModeValue("gray.50", "gray.800")}
      borderColor={useColorModeValue("gray.50", "gray.700")}
      borderRightWidth="1px"
      w="60"
      {...props}
    >
      <Flex
        px="4"
        py="7"
        align="center"
        justifyContent="center"
        marginBottom="20px"
      >
        <Image
          src={
            colorMode === "dark" ? DarkModeAlphabitLogo : LightModeAlphabitLogo
          }
          alt="logo"
          height="50px"
          width="75px"
        />
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        <NavItem
          to="/leaderboard"
          icon={FiAward}
          onClick={leaderboard.onToggle}
        >
          Leaderboard
        </NavItem>
        <Collapse in={leaderboard.isOpen} animateOpacity>
          <NavItem pl="14" py="10" pb="3" pt="3" to="/borrowers">
            Borrowers
          </NavItem>
          <NavItem pl="14" py="10" pb="3" pt="3" to="/stakers">
            Stakers
          </NavItem>
          <NavItem pl="14" py="10" pb="3" pt="3" to="/whales">
            Whales
          </NavItem>
        </Collapse>
        <NavItem
          onClick={integrations.onToggle}
          icon={FiActivity}
          to="/following"
        >
          Following
        </NavItem>
        <Collapse in={integrations.isOpen}>
          <NavItem pl="14" py="10" pb="3" pt="3" to="/transactions">
            Transactions
          </NavItem>
        </Collapse>
        <NavItem icon={FiHome} to="/wallet">
          My Portfolio
        </NavItem>
        <NavItem icon={FiUmbrella} to="/parachains">
          Parachains
        </NavItem>
        <NavItem
          to="/dashboard/r7bRf6SSrkLtivGdRgm2oG1bdYqXRmCUjygevF32ziXrZNW"
          icon={FaWallet}
        >
          Example Wallet
        </NavItem>
      </Flex>
      <Box
        style={{
          position: "absolute",
          bottom: 0,
          paddingBottom: "10px",
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <FiInstagram size="20px" color={socialColor} />
        <FiTwitter size="20px" color={socialColor} />
      </Box>
    </Box>
  );
  return (
    <Box as="section" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent display={{ base: "none", md: "unset" }} />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
        <Flex
          as="header"
          align="center"
          justify={!isFollowerOrWalletPage ? "space-between" : "end"}
          w="full"
          px="4"
          bg={useColorModeValue("gray.100", "gray.900")}
          paddingTop="30px"
          marginBottom="30px"
          h="14"
        >
          <IconButton
            aria-label="Menu"
            display={{ base: "inline-flex", md: "none" }}
            onClick={sidebar.onOpen}
            icon={<FiMenu />}
            size="sm"
          />
          {!isFollowerOrWalletPage && (
            <Search
              placeholder="Search for wallets..."
              onChange={onSearch}
              onKeyPress={onKeyPress}
            />
          )}
          <Flex alignItems="center">
            <Switch
              paddingRight="5"
              onChange={toggleColorMode}
              colorScheme="green"
            />
            <Icon color="gray.500" as={FaBell} cursor="pointer" />
            <Avatar
              ml="4"
              size="sm"
              name="anubra266"
              src="https://avatars.dicebear.com/api/bottts/1.svg"
              cursor="pointer"
            />
          </Flex>
        </Flex>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
