import React from "react";
import { Container, chakra, useColorModeValue } from "@chakra-ui/react";
import Transactions from "@/Transactions/Transactions";

function Activity({ walletAddress }) {
  return (
    <Container borderRadius="16" padding="6" margin="6" variant="wallet">
      <chakra.h4
        fontSize={{ base: "3xl", sm: "4xl", lg: "xl" }}
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight="shorter"
        color={useColorModeValue("gray.400", "gray.100")}
        display="flex"
        pb="5"
      >
        Wallet Recent Transactions
      </chakra.h4>
      <Transactions wallet={walletAddress} />
    </Container>
  );
}

export default Activity;
