import { mode } from "@chakra-ui/theme-tools";
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
  dark: "blue",
};

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.100", "gray.900")(props),
      },
    }),
  },
  config,
  fonts: {
    body: "Nunito Sans",
    heading: "Nunito Sans",
  },
  colors: {
    gray: {
      900: "#1F242F",
      800: "#14171F",
      400: "#73738B",
      200: "#252531",
      100: "#f5f5f5",
      50: "#FFF",
    },
    green: {
      200: "#10E3A4",
      400: "#10E3A4",
      500: "#10E3A4",
    },
  },
  components: {
    Button: {
      variants: {
        alphabitPrimary: (props) => ({
          background: "green.400",
          borderRadius: "20px",
          color: props.colorMode === "dark" ? "gray.200" : "gray.50",
        }),
      },
    },
    Container: {
      variants: {
        wallet: (props) => ({
          maxWidth: "container.lg",
          background: props.colorMode === "dark" ? "black" : "white",
        }),
      },
    },
    Input: {
      variants: {
        alphabit: {
          field: {
            borderRadius: "20px",
            border: "#2F3542",
          },
        },
      },
    },
    Table: {
      variants: {
        alphabitDark: {
          td: {
            paddingBottom: "7px",
            paddingTop: "7px",
          },
          tr: {
            _even: {
              background: "#2F3542",
            },
            _hover: {
              background: "gray.700",
            },
          },
          th: {
            color: "gray.400",
          },
        },
        alphabitLight: {
          td: {
            paddingBottom: "7px",
            paddingTop: "7px",
          },
          tr: {
            _even: {
              background: "white",
            },
            _hover: {
              background: "white",
            },
          },
          th: {
            color: "#c6cad3",
          },
        },
      },
    },
    Tabs: {
      variants: {
        alphabit: (props) => ({
          tab: {
            fontSize: "14px",
            fontWeight: "600",
            width: "100px",
            color: props.colorMode === "dark" ? "white" : "black",
            borderRadius: "20px",
            _hover: {
              background: "green.400",
              color: props.colorMode === "dark" ? "gray.200" : "white",
            },
            _selected: {
              background: "green.400",
              color: props.colorMode === "dark" ? "gray.200" : "white",
            },
          },
        }),
        wallet: (props) => ({
          tab: {
            color: props.colorMode === "dark" ? "white" : "gray.200",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            marginRight: "10px",
            width: "150px",
            background: props.colorMode === "dark" ? "#2F3542" : "gray.300",
            _selected: {
              background: "green.400",
              color: props.colorMode === "dark" ? "gray.200" : "gray.50",
              fontWeight: "bold",
            },
            _hover: {
              background: "green.400",
              color: "gray.200",
            },
          },
          tablist: {
            marginLeft: "30px",
            borderBottom:
              props.colorMode === "dark"
                ? "1px solid #383848"
                : "1px solid #e8ebee",
          },
        }),
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "#383848",
      },
    },
  },
});

export default theme;
