import { useQuery } from "react-query";
import { KARURA_SUBQUERY_ENDPOINT } from "@/utils";
import { request, gql } from "graphql-request";

function mapAccountExtrinsics(extrinsics) {
  const stats = extrinsics?.nodes.reduce((accum, currentValue) => {
    if (!accum[currentValue.method]) {
      accum[currentValue.method] = [];
    }
    accum[currentValue.method].push(currentValue);
    return accum;
  }, {});

  return Object.keys(stats).map((key) => ({
    name: key,
    count: stats[key].length,
  }));
}

export const useAccountExtrinsics = (walletAddress) => {
  return useQuery(["topKaruraWallets", walletAddress], async () => {
    const { account } = await request(
      KARURA_SUBQUERY_ENDPOINT,
      gql`
        query Account($address: String!) {
          account(id: $address) {
            id
            extrinsics(orderBy: TIMESTAMP_DESC) {
              nodes {
                id
                section
                method
                isSuccess
                timestamp
              }
            }
          }
        }
      `,
      {
        address: walletAddress,
      }
    );
    return mapAccountExtrinsics(account.extrinsics);
  });
};
