import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { POLKADATA_EXTERNAL_API } from "@/utils";

const PIE_CHART_KEYS = ["total_collateral_usd", "total_debt_usd"];
const STAKED_KEY_MAPPER = {
  amount_stake_ksm: {
    tokenDisplay: "KSM",
  },
};

const POOL_KEY_MAPPER = {
  amount_lp_kar_ksm: {
    tokenDisplay: "KAR/KSM",
    firstTokenImage: "kar.svg",
    secondTokenImage: "ksm.png",
  },
  amount_lp_kar_lksm: {
    firstTokenImage: "kar.svg",
    secondTokenImage: "lksm.svg",
    tokenDisplay: "KAR/LKSM",
  },
  amount_lp_kusd_ksm: {
    tokenDisplay: "KSM/KUSD",
    firstTokenImage: "ksm.png",
    secondTokenImage: "kusd.svg",
  },
  amount_lp_kusd_lksm: {
    tokenDisplay: "LKSM/KUSD",
    firstTokenImage: "lksm.svg",
    secondTokenImage: "kusd.svg",
  },
  amount_lp_ksm_lksm: {
    tokenDisplay: "LKSM/KSM",
    firstTokenImage: "lksm.svg",
    secondTokenImage: "ksm.png",
  },
};

export const useCollateralRatio = (walletAddress) => {
  const queryClient = useQueryClient();
  return useQuery(["collateralRatio", walletAddress], async () => {
    try {
      const { data } = await axios.get(
        `${POLKADATA_EXTERNAL_API}/accounts/${walletAddress}/`,
        {
          headers: {
            API_KEY: process.env.REACT_APP_API_KEY,
          },
        }
      );

      const { debit, transfer, collateral, ratio, ...rest } = data;
      queryClient.setQueryData(
        "stakedTokens",
        convertFromKeyMapper(rest, STAKED_KEY_MAPPER)
      );

      queryClient.setQueryData(
        "liquidityPools",
        convertFromKeyMapper(rest, POOL_KEY_MAPPER)
      );

      return convertToPieChartShape(data);
    } catch (error) {
      console.error(error);
    }
  });
};

export const convertFromKeyMapper = (data, keyMapper) => {
  return Object.keys(data).flatMap((key) => {
    if (keyMapper[key]) {
      return {
        tokenInformation: { ...keyMapper[key] },
        value: data[key],
        usd: data["amount_stake_usd"] || 0,
      };
    }
    return [];
  });
};

export const convertToPieChartShape = (data) => {
  return Object.keys(data).flatMap((key) => {
    if (PIE_CHART_KEYS.includes(key)) {
      return [
        {
          key,
          value: data[key],
        },
      ];
    } else {
      return [];
    }
  });
};
