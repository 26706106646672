import React from "react";
import dayjs from "dayjs";
import { Area } from "@ant-design/plots";

function Chart({ data, xField, yField }) {
  const config = {
    data,
    xField,
    yField,
    smooth: true,
    padding: "auto",
    tooltip: {
      showTitle: false,
      customItems: (originalItems) => {
        return originalItems;
      },
      formatter: (data) => {
        return { name: "Number of Activities", value: data.count };
      },
    },
    xAxis: {
      label: {
        formatter: (text) => {
          switch (xField) {
            case "hour":
              return dayjs().hour(text).format("hh:00 A");
            case "day":
              return dayjs().date(text).format("DD");
            case "month":
              console.log(dayjs().month(text).format("MMM"));
              return dayjs().month(text).format("MMM");
            default:
              break;
          }
        },
        autohide: true,
      },
      tickCount: (() => {
        switch (xField) {
          case "hour":
            return 5;
          case "month":
            return 12;
          default:
            break;
        }
      })(),
    },
    yAxis: {
      label: null,
      grid: null,
    },
  };
  return data?.length ? <Area {...config} /> : null;
}

export default Chart;
