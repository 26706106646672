import React from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

const Search = ({ value, onChange, onKeyPress, placeholder, style }) => {
  const inputThemeColor = useColorModeValue("gray.50", "#2F3542");
  const inputIconColor = useColorModeValue("gray.100", "gray.50");

  return (
    <InputGroup w="96" display={{ base: "none", md: "flex" }}>
      <InputLeftElement color={inputIconColor} children={<FiSearch />} />
      <Input
        bg={inputThemeColor}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
        style={style}
        value={value}
        variant="alphabit"
      />
    </InputGroup>
  );
};

export default Search;
