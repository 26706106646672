import React, { useState } from "react";
import { Container, chakra, Grid, GridItem, Select } from "@chakra-ui/react";
import LineChart from "@/LineChart/LineChart";
import { useUserActivity } from "./useUserActivity";

function UserActivity({ walletAddress }) {
  const [option, setOption] = useState("hourly");
  const { data: userActivity } = useUserActivity(walletAddress, option);

  let xField;

  switch (option) {
    case "hourly":
      xField = "hour";
      break;
    case "daily":
      xField = "day";
      break;
    case "monthly":
      xField = "month";
      break;
    default:
      break;
  }

  return (
    <Container borderRadius="6" padding="6" margin="6" variant="wallet">
      <Grid templateColumns="repeat(5, 1fr)">
        <chakra.h4
          fontSize={{ base: "3xl", sm: "4xl", lg: "xl" }}
          fontWeight="extrabold"
          letterSpacing="tight"
          lineHeight="shorter"
          color={"gray.100"}
          mb="3"
        >
          User Activity
        </chakra.h4>
        <GridItem colStart={5}>
          <Select
            value={option}
            placeholder="Select option"
            onChange={({ target: { value } }) => setOption(value)}
          >
            <option value="hourly">Hourly</option>
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
          </Select>
        </GridItem>
      </Grid>
      <LineChart xField={xField} yField="count" data={userActivity} />
    </Container>
  );
}

export default UserActivity;
