import React, { useEffect, useState } from "react";
import {
  chakra,
  Box,
  Divider,
  Flex,
  Text,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import Dashboard from "@/Wallet/Dashboard/Dashboard";
import Search from "@/Generics/Search/Search";

export default function MyWallet() {
  const [wallet, setWallet] = useState("");
  const [renderDashboard, setRenderDashboard] = useState(false);
  const theme = useColorModeValue("gray.100", "gray.900");
  const textColor = useColorModeValue("black", "gray.100");

  useEffect(() => {
    if (localStorage.getItem("wallet")) {
      setRenderDashboard(true);
    }
  }, [wallet]);

  const handleInput = ({ target: { value } }) => {
    setWallet(value);
  };

  const onSubmit = () => {
    localStorage.setItem("wallet", wallet);
    setWallet("");
  };

  return (
    <Box>
      {renderDashboard ? (
        <Dashboard wallet={localStorage.getItem("wallet")} />
      ) : (
        <Box bg={theme}>
          <Box bg={theme} p={50} paddingTop={0}>
            <chakra.h2
              fontSize={{ base: "3xl", sm: "4xl" }}
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="shorter"
            >
              <chakra.span display="block" color={textColor}>
                Ready to dive in?
              </chakra.span>
              <Text display="block" color={"green.400"}>
                Add your wallet address today.
              </Text>
            </chakra.h2>
            <Flex pt="25">
              <Search
                value={wallet}
                placeholder="Add Wallet Address"
                onChange={handleInput}
                style={{ marginRight: "10px" }}
              />
              <Button variant="alphabitPrimary" onClick={onSubmit}>
                Submit
              </Button>
            </Flex>
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );
}
