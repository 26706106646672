import React from "react";
import {
  Table,
  Tooltip,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useTransactions } from "./useTransactions";

dayjs.extend(LocalizedFormat);

function Transactions({ wallet }) {
  const { data, isFetching } = useTransactions(wallet);
  const tableTheme = useColorModeValue("alphabitLight", "alphabitDark");

  return (
    <Box as="main">
      <Table variant={tableTheme} style={{ tableLayout: "fixed" }}>
        <Thead>
          <Tr>
            <Th>From</Th>
            <Th>To</Th>
            <Th>Time</Th>
            <Th isNumeric>Amount (KAR)</Th>
            <Th>Follow</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isFetching &&
            data?.transfers?.map((transfer, idx) => {
              return (
                <Tr key={idx}>
                  <Td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Tooltip
                      label={
                        transfer.from_account_display.display || transfer.from
                      }
                      aria-label="Transfer From tooltip"
                    >
                      {transfer.from_account_display.display || transfer.from}
                    </Tooltip>
                  </Td>
                  <Td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Tooltip
                      label={transfer.to_account_display.display || transfer.to}
                      aria-label="Transfer To Tooltip"
                    >
                      {transfer.to_account_display.display || transfer.to}
                    </Tooltip>
                  </Td>
                  <Td>
                    {dayjs(transfer?.block_timestamp * 1000).format("LLL")}
                  </Td>
                  <Td isNumeric>{transfer.amount} </Td>
                  <Td isNumeric>
                    <ExternalLinkIcon
                      style={{
                        cursor: "pointer",
                        color: "green.400",
                        paddingRight: "2px",
                      }}
                      onClick={() =>
                        window.open(
                          `https://karura.subscan.io/extrinsic/${transfer.extrinsic_index}`,
                          "_blank"
                        )
                      }
                    />
                    <span style={{ verticalAlign: "middle" }}>Link</span>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Transactions;
