import React from "react";
import { useHistory } from "react-router-dom";
import { Table, Thead, Tr, Th, Tbody, Td, Box } from "@chakra-ui/react";
import { useTransfers } from "./useTransfers";
import { formatNumberToCurrency } from "@/utils";

function Transfers({ walletAddress }) {
  const history = useHistory();
  const { data, isFetching } = useTransfers(walletAddress);

  return (
    <Box as="main">
      <Table variant="alphabit">
        <Thead>
          <Tr>
            <Th>Address</Th>
            <Th>Total Volume</Th>
            <Th>Received</Th>
            <Th>Sent</Th>
            <Th>Number of Transfers</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isFetching &&
            data?.map(
              (
                { address, count, volume, volume_receive, volume_send },
                idx
              ) => {
                return (
                  <Tr key={idx}>
                    <Td
                      onClick={() => history.push(`/dashboard/${address}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {address}
                    </Td>
                    <Td isNumeric>{formatNumberToCurrency(volume)}</Td>
                    <Td isNumeric>{formatNumberToCurrency(volume_receive)}</Td>
                    <Td isNumeric>{formatNumberToCurrency(volume_send)} </Td>
                    <Td>{count}</Td>
                  </Tr>
                );
              }
            )}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Transfers;
