import axios from "axios";
import { useQuery } from "react-query";
import { KARURA_SUBSCAN_ENDPOINT } from "@/utils";

export const useTransactions = (wallet) => {
  return useQuery("transactions", async () => {
    try {
      const {
        data: { data },
      } = await axios.post(`${KARURA_SUBSCAN_ENDPOINT}/api/scan/transfers`, {
        row: 100,
        page: 0,
        address: wallet ?? "",
      });
      return data;
    } catch (error) {
      console.error(error);
    }
  });
};
