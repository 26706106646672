import React from "react";
import UserActivity from "./UserActivity";
import LifetimeExtrinsics from "./LifetimeExtrinsics";
import CollateralRatio from "./CollateralRatio";

function Metrics({ accountExtrinsics, collateral, walletAddress }) {
  return (
    <div>
      <UserActivity walletAddress={walletAddress} />
      <LifetimeExtrinsics accountExtrinsics={accountExtrinsics} />
      <CollateralRatio collateral={collateral} />
    </div>
  );
}

export default Metrics;
