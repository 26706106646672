import React from "react";
import { chakra, Container } from "@chakra-ui/react";
import BarChart from "@/BarChart/BarChart";

function LifetimeExtrinsics({ accountExtrinsics }) {
  return (
    <Container borderRadius="6" padding="6" margin="6" variant="wallet">
      <chakra.h2
        fontSize={{ base: "3xl", sm: "4xl", lg: "xl" }}
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight="shorter"
        color={"gray.100"}
      >
        Lifetime Extrinsics
      </chakra.h2>
      <BarChart xField="name" yField="count" data={accountExtrinsics} />
    </Container>
  );
}

export default LifetimeExtrinsics;
