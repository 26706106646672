import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  Text,
  Flex,
  TabPanel,
  Divider,
  TabPanels,
  Tab,
} from "@chakra-ui/react";
import WhaleTable from "./WhaleTable";
import { useWhaleActivity } from "./useWhaleActivity";

const SELECTED_DAYS_KEYS = ["1", "7", "30"];

function Whales() {
  const [selectedDays, setSelectedDays] = useState("1");
  const { data, isFetching } = useWhaleActivity(selectedDays);

  const onTabChange = (event) => {
    setSelectedDays(SELECTED_DAYS_KEYS[event]);
  };

  return (
    <Box as="main">
      <Tabs variant="alphabit" onChange={onTabChange}>
        <TabList>
          <Flex>
            <Text
              color="gray.400"
              textTransform="uppercase"
              fontSize="12px"
              alignSelf="center"
              pl="9"
              pr="7"
            >
              Select Option:
            </Text>
            <Tab>Daily</Tab>
            <Tab>Weekly</Tab>
            <Tab>Monthly</Tab>
          </Flex>
        </TabList>
        <Divider pt="5" />
        <TabPanels>
          {isFetching &&
            SELECTED_DAYS_KEYS.map(() => (
              <TabPanel>
                <WhaleTable data={data} />
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Whales;
