import React from "react";
import { Pie } from "@ant-design/plots";
import { formatNumberToCurrency } from "@/utils";

const formatNames = (name) => {
  switch (name) {
    case "total_debt_usd":
      return "Debt";
    case "total_collateral_usd":
      return "Collateral";
    default:
      break;
  }
};

function Chart({ data }) {
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "key",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    legend: {
      itemName: {
        formatter: (text, value) => {
          return formatNames(text);
        },
        style: {
          fontSize: 14,
        },
      },
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: formatNames(data.key),
          value: formatNumberToCurrency(data.value),
        };
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return data?.length ? <Pie {...config} /> : null;
  // <PieChart
  //   width={400}
  //   height={300}
  //   margin={{
  //     top: 5,
  //     bottom: 5,
  //   }}
  //   barSize={40}
  // >
  //   {data?.length && (
  //     <Legend
  //       layout="horizontal"
  //       verticalAlign="bottom"
  //       align="center"
  //       payload={data.map((item, index) => ({
  //         id: item.key,
  //         type: "square",
  //         value: (() => {
  //           if (item.key === "ratio") {
  //             return `Ratio: ${item.value}%`;
  //           } else if (item.key === "debt_usd") {
  //             return `Debt: ${formatNumberToCurrency(item.value)}`;
  //           } else {
  //             return `Collateral: ${formatNumberToCurrency(item.value)}`;
  //           }
  //         })(),
  //         color: colors[index % colors.length],
  //       }))}
  //     />
  //   )}
  //   <Pie
  //     data={data}
  //     dataKey="value"
  //     nameKey="key"
  //     fill="white"
  //     label={({
  //       payload,
  //       cx,
  //       cy,
  //       midAngle,
  //       innerRadius,
  //       outerRadius,
  //       percent,
  //       index,
  //     }) => {
  //       if (payload?.key === "ratio") {
  //         return null;
  //       }
  //       const radius = 25 + innerRadius + (outerRadius - innerRadius);
  //       const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //       const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //       return (
  //         <text
  //           x={x}
  //           y={y}
  //           textAnchor={x > cx ? "start" : "end"}
  //           fill={"white"}
  //           dominantBaseline="central"
  //         >
  //           {payload?.key}
  //         </text>
  //       );
  //     }}
  //   >
  //     {data?.map((entry, index) => (
  //       <Cell key={`cell-${index}`} fill={colors[index]} />
  //     ))}
  //   </Pie>
  // </PieChart>
}

export default Chart;
