import axios from "axios";
import { useQuery } from "react-query";
import { POLKADATA_EXTERNAL_API } from "@/utils";

export const useTopStakers = () => {
  return useQuery("topStakers", async () => {
    try {
      const { data } = await axios.get(
        `${POLKADATA_EXTERNAL_API}/accounts/top_stakers/`,
        {
          headers: {
            API_KEY: process.env.REACT_APP_API_KEY,
          },
        }
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  });
};
