import { useQuery, useQueryClient } from "react-query";
import Talisman from "@talismn/api";

export const nameToTickerMap = {
  DOT: "polkadot",
  KSM: "kusama",
  KAR: "karura",
  MOVR: "moonriver",
  GLMR: "moonbeam",
  // AIR: "altair",
  ACA: "acala",
  ASTR: "astar",
  KMA: "calamari-network",
};

export const CHAINS_TO_ID = {
  POLKADOT: "0",
  KUSAMA: "2",
  KARURA: "2-2000",
  MOONRIVER: "2-2023",
  CALAMARI: "2-2084",
  // ALTAIR: "2-2088",
  ACALA: "0-2000",
  MOONBEAM: "0-2004",
  ASTAR: "0-2006",
};

export const useGetAccountDetails = (walletAddress) => {
  const queryClient = useQueryClient();

  return useQuery(["accountDetails", walletAddress], async () => {
    try {
      const chains = await Talisman.connect({
        chains: Object.values(CHAINS_TO_ID),
      });
      const balances = await Talisman.balances([walletAddress]);

      const tokenDetails = queryClient.getQueryData("getTokenDetails");

      if (balances.length) {
        return balances.map((entity) => {
          return {
            ...entity,
            // Multiply balancess by Math.pow(10, -12);
            free: entity.free * Math.pow(10, -12),
            currentPrice: tokenDetails[nameToTickerMap[entity.token]]?.usd,
          };
        });
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  });
};
