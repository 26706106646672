import React from "react";
import {
  chakra,
  Container,
  Image,
  Flex,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatNumberToCurrency } from "@/utils";

function LiquidityPools({ liquidityPools }) {
  return (
    <Container
      maxHeight="100%"
      borderRadius="16"
      padding="6"
      w="100%"
      marginLeft="6"
      marginTop="6"
      marginRight="6"
      variant="wallet"
    >
      <chakra.h4
        fontSize={{ base: "3xl", sm: "4xl", lg: "xl" }}
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight="shorter"
        color={useColorModeValue("gray.400", "gray.100")}
        mb="3"
      >
        Pools
      </chakra.h4>
      {liquidityPools?.length ? (
        <Table>
          <Thead>
            <Tr>
              <Th>Token</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {liquidityPools.map(({ tokenInformation, value }) => {
              return (
                <Tr>
                  <Td>
                    <Flex>
                      <Image
                        width="30px"
                        height="30px"
                        src={`/tokens/${tokenInformation.firstTokenImage}`}
                      />
                      <Image
                        style={{
                          position: "relative",
                          right: "10px",
                        }}
                        width="30px"
                        height="30px"
                        src={`/tokens/${tokenInformation.secondTokenImage}`}
                      />
                      {tokenInformation.tokenDisplay}
                    </Flex>
                  </Td>
                  <Td>{formatNumberToCurrency(value)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <p>No LP Tokens</p>
      )}
    </Container>
  );
}

export default LiquidityPools;
