import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Activity from "./Activity";
import Metrics from "./Metrics";
import Holdings from "@/Wallet/Holdings/Holdings";
import Transfers from "@/Wallet/Transfers/Transfers";
import { useParams } from "react-router-dom";
import { useAccountExtrinsics } from "./useExtrinsics";
import { useGetAccountDetails } from "./useGetAccountDetails";
import { useGetTokenDetails } from "@/Leaderboard/useTopKaruraWallets";
import { useCollateralRatio } from "./useCollateralRatio";

function Dashboard({ wallet }) {
  const { address } = useParams();

  const queryClient = useQueryClient();

  const walletAddress = address ? address : wallet;

  const { data: accountExtrinsics } = useAccountExtrinsics(walletAddress);
  const { data: accountDetails, isFetching } =
    useGetAccountDetails(walletAddress);
  const { data: collateral } = useCollateralRatio(walletAddress);
  const { data: tokens } = useGetTokenDetails();
  const stakedTokens = queryClient.getQueryData("stakedTokens");
  const liquidityPools = queryClient.getQueryData("liquidityPools");

  return (
    <div>
      <Tabs variant="wallet">
        <TabList mb="1em">
          <Tab>Holdings</Tab>
          <Tab>Metrics</Tab>
          <Tab>Activity</Tab>
          <Tab>Transfers</Tab>
        </TabList>
        <TabPanels
          _focus={{
            boxShadow: "none",
          }}
        >
          <TabPanel
            style={{ outline: "none", boxShadow: "none" }}
            _focus={{ boxShadow: "none", outline: "none" }}
          >
            <Holdings
              accountDetails={accountDetails}
              stakedTokens={stakedTokens}
              liquidityPools={liquidityPools}
              tokens={tokens}
              walletAddress={walletAddress}
              isFetching={isFetching}
            />
          </TabPanel>
          <TabPanel>
            <Metrics
              accountExtrinsics={accountExtrinsics}
              collateral={collateral}
              walletAddress={walletAddress}
            />
          </TabPanel>
          <TabPanel>
            <Activity walletAddress={walletAddress} />
          </TabPanel>
          <TabPanel>
            <Transfers walletAddress={walletAddress} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default Dashboard;
