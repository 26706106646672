import React from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableCaption,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTopKaruraWallets } from "./useTopKaruraWallets";

function Leaderboard() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data, isFetching } = useTopKaruraWallets();

  const tokens = queryClient.getQueryData("tokenDetails");

  const tableTheme = useColorModeValue("alphabitLight", "alphabitDark");

  return (
    <Box as="main">
      <Table variant={tableTheme}>
        <TableCaption>Karura Top Wallets</TableCaption>
        <Thead>
          <Tr>
            <Th>Place</Th>
            <Th>Trader</Th>
            <Th>Wallet</Th>
            <Th isNumeric>Token Balance</Th>
            <Th isNumeric>USD Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isFetching &&
            data.map((account, idx) => {
              return (
                <Tr style={{ cursor: "pointer" }} key={idx}>
                  <Td>{idx + 1}</Td>
                  <Td>
                    <Avatar
                      size={"sm"}
                      src={`https://avatars.dicebear.com/api/bottts/${account.address}.svg`}
                    />
                  </Td>
                  <Td
                    onClick={() =>
                      history.push(`/dashboard/${account.address}`)
                    }
                  >
                    {account.address}
                  </Td>
                  <Td isNumeric>
                    {new Intl.NumberFormat().format(account.balance_kar)} KAR
                  </Td>
                  <Td isNumeric>
                    $
                    {new Intl.NumberFormat().format(
                      account.balance_kar * tokens?.karura?.usd
                    )}{" "}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Leaderboard;
