import React from "react";
import {
  chakra,
  Box,
  Container,
  Flex,
  Image,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { tokenToImage, formatNumberToCurrency, TOKEN_MAP } from "@/utils";

function Portfolio({ walletAddress, accountDetails, isFetching, tokens }) {
  return (
    <Container
      maxHeight="450"
      borderRadius="16"
      padding="6"
      marginLeft="6"
      marginTop="6"
      marginRight="6"
      variant="wallet"
      overflowY="auto"
    >
      <chakra.h4
        fontSize={{ base: "3xl", sm: "4xl", lg: "xl" }}
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight="shorter"
        color={useColorModeValue("gray.400", "gray.100")}
        mb="3"
      >
        Holdings
      </chakra.h4>
      {isFetching && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
      {accountDetails?.length && !isFetching ? (
        <Table>
          <Thead>
            <Tr>
              <Th>Token</Th>
              <Th>Amount (Token)</Th>
              <Th>Amount (USD)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {accountDetails.map(({ free, token, currentPrice }, idx) => {
              return (
                <Tr key={idx}>
                  <Td>
                    <Flex alignItems={"center"}>
                      <Image
                        src={tokenToImage(token)}
                        boxSize="30px"
                        borderRadius={"50%"}
                        marginRight="3"
                      />
                      <p>{token}</p>
                    </Flex>
                  </Td>
                  <Td>
                    {token === TOKEN_MAP.USD
                      ? formatNumberToCurrency(free)
                      : free}{" "}
                  </Td>
                  <Td>{formatNumberToCurrency(free * currentPrice)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <div>{!isFetching && <p>No Portfolio Holdings</p>}</div>
      )}
    </Container>
  );
}

export default Portfolio;
