import React from "react";
import { useHistory } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import {
  Avatar,
  Table,
  chakra,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { formatNumberToCurrency } from "@/utils";

function WhaleTable({ data }) {
  const history = useHistory();
  const columns = React.useMemo(
    () => [
      {
        Header: "Place",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Trader",
        accessor: ({ address }) => (
          <Avatar
            size={"sm"}
            src={`https://avatars.dicebear.com/api/bottts/${address}.svg`}
          />
        ),
      },
      {
        Header: "Wallet",
        accessor: ({ address }) => address,
      },
      {
        Header: "Volume",
        accessor: ({ volume }) => formatNumberToCurrency(volume),
        isNumeric: true,
      },
      {
        Header: "Count",
        accessor: "count",
        isNumeric: true,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Table variant="alphabit" {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                isNumeric={column.isNumeric}
              >
                {column.render("Header")}
                <chakra.span pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr style={{ cursor: "pointer" }} {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td
                  onClick={() =>
                    history.push(`/dashboard/${row.original.address}`)
                  }
                  {...cell.getCellProps()}
                  isNumeric={cell.column.isNumeric}
                >
                  {cell.render("Cell")}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export default WhaleTable;
